import React from "react"

const About = () => {
  return (
    <div className="widget">
      <h6 className="title">About The Author</h6>
      <hr />
      <p>
        HI THERE! I am Tayo and these are my thoughts around photography and
        life.
      </p>
    </div>
  )
}

export default About
